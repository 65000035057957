import gql from 'graphql-tag';

const anioFiscalTableQuery = gql`
    query anios_fiscales($whereConditions:AniosFiscalesWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!) {
        anios_fiscales(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: DESC}]) {
            data{
                id
                cliente_id,
                empresa_id,
                anio_id,
                descripcion,
                fecha_inicio,
                fecha_final,
                fecha_cierre,
                actual,
                cerrado,
                periodos{
                    numero,
                    descripcion
                },
                cliente{
                    id,
                    nombre
                },
                empresa{
                    id,
                    nombre
                },
                anio{
                    id,
                    nombre
                }
            }  
            paginatorInfo{
                currentPage
                lastItem
                hasMorePages
                lastPage
                total
                firstItem
            }
        }
    }
`

const queries = { anioFiscalTableQuery };

export default queries;
